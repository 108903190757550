<template>
  <div style="margin: 41px 40px">
    <Modal
      width="40%"
      v-if="isModalDetail"
      @close="() => (isModalDetail = false)"
    >
      <h1 class="modal-title">Konfirmasi Deactive Data</h1>
      <p style="margin: 20px 0px" class="detail-value">
        Apakah kamu yakin ingin menonaktifkan data transaksi pelanggan ini?
      </p>
      <div class="btn-container-2">
        <button
          @click.prevent="getmodal(false)"
          class="color-text-gray btn-batalkan"
        >
          BATALKAN
        </button>
        <button style="margin-left: 10px" class="color-text-gray btn-simpan-2">
          SIMPAN DATA
        </button>
      </div>
    </Modal>
    <div style="display: flex; align-items: center; margin-bottom: 40px">
      <h5 class="text-page" style="margin-right: 10px">Finance</h5>
      <Arrow :fill="'#4456b4'" :direct="'left'" />
      <h5 class="text-page" style="margin: 10px">(B2C) Event</h5>
      <Arrow :fill="'#4456b4'" :direct="'left'" />
      <h5 class="text-page" style="margin: 10px">
        {{ this.category_name }}
      </h5>
      <Arrow :fill="'#4456b4'" :direct="'left'" />
      <h5 class="text-page color-text-gray" style="margin-left: 10px">
        Riwayat
      </h5>
    </div>
    <div class="body">
      <div v-if="category.length" class="body-content">
        <div
          class="box-category cursor-pointer"
          v-for="(item, index) in category"
          :key="index"
          @click="goToPage(item.id)"
        >
          <div style="margin-right: auto">
            <div class="warp-box-category">
              <div>
                <h1 class="box-category-title" >
                  {{ item.name }}
                </h1>
                <p style="margin-right: auto" class="box-category-text">
                  {{ item.schedule || "01 Mar 2024" }}
                </p>
              </div>
              <div style="margin-left: auto">
                <Arrow :direct="true" />
              </div>
            </div>
            <div class="box-category-count">
              <div>
                <h3 class="box-category-count-title">
                  {{ item.total_session }}
                </h3>
                <h5 class="box-category-count-text">
                  Total <br />
                  Program
                </h5>
              </div>
              <div>
                <h3 class="box-category-count-title">
                  {{ item.total_registered }}
                </h3>
                <h5 class="box-category-count-text">
                  Peserta <br />
                  Terdaftar
                </h5>
              </div>
              <div>
                <h3 class="box-category-count-title">
                  {{
                    item.total_revenue >= 1000
                      ? (item.total_revenue / 1000).toFixed(0) + "k"
                      : item.total_revenue.toString()
                  }}
                </h3>
                <h5 class="box-category-count-text">
                  Total<br />
                  Revenue
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p class="text-jumlah">
          Menampilkan <span style="font-weight: 700">{{ this.jumlah.total_data }}</span> pada
          <span style="font-weight: 700">{{ this.jumlah.total_page }}</span>
          page
        </p>
        <div class="pegenation">
          <Arrow :fill="'#4456b4'" :direct="'right'" />
          <h5
            v-for="n in jumlah.total_page"
            :key="n"
            class="pegenation-text color-text-blue"
          >
            {{ n }}
          </h5>
          <Arrow :fill="'#4456b4'" :direct="'left'" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Arrow from "../../../../../components/icons/Arrow.vue";

export default {
  props: ["categorys"],
  components: {
    Arrow,
  },
  data() {
    return {
      category: [],
      jumlah: null,
      category_name: "",
    };
  },
  methods: {
    async fetchTransactionEvent() {
      try {
        const response = await this.$crm_http.get(`/v1/transaction/event/category/${this.categorys}?page=1&per_page=12&history=true`)
        this.category = response.data.data.events;
        this.jumlah = response.data.data.pagination;
        this.category_name = response.data.data.category_name;
      } catch (error) {
        console.error("Error fetching transaction events:", error);
      }
    },
    goToPage(id) {
      this.$router.push(`/transaction/b2b/event/riwayat/${id}`);
    },
  },
  mounted() {
    this.fetchTransactionEvent();
  },
};
</script>
<style scoped>
.text-page {
  font-weight: 500;
  font-size: 16px;
  color: #162ca2;
}
.color-text-blue {
  color: #4456b4;
}
.color-text-gray {
  color: #acafbf;
}
.text-jumlah {
  font-size: 12px;
  font-weight: 400px;
  margin-top: 46px;
}
.pegenation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.pegenation-text {
  font-size: 14px;
  font-weight: 700;
  margin-left: 15px;
  margin-right: 15px;
}
.warp-box-category {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.body {
  background: #ffffff;
  padding: 42px 22px;
  border-radius: 10px;
  height: fit-content;
}

.body-content {
  display: grid;
  gap: 25px 35px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
  justify-items: center;
}

.box-category2 {
  border: 1px solid #2d46cf;
  width: 100%;
  padding: 15px 25px;
  border-radius: 15px;
}
.box-category {
  border: 1px solid #d3d6e1;
  width: 100%;
  padding: 15px 25px;
  border-radius: 15px;
}
.box-category-count {
  width: fit-content;
  display: flex;
  padding: 15px 25px 15px 25px;
  column-gap: 38px;
  background-color: #f1f6ff;
  border-radius: 5px;
}

.box-category-text {
  color: #7b7e8c;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  width: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.box-category-title {
  color: #36373f;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
}
.box-category-count-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #2d46cf;
}
.box-category-count-text {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
}
</style>
